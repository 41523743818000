import * as React from 'react'

import './Footer.scss'

// tslint:disable-next-line
interface FooterProps {}

const Footer: React.FC<FooterProps> = () => (
  <div className="footer">
    <p>&copy; 2022 Adventure in the Parks</p>
  </div>
)

export default Footer
