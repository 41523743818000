import * as React from 'react'

import { PageProps, graphql } from 'gatsby'
import { withPrismicPreview, WithPrismicPreviewProps } from 'gatsby-plugin-prismic-previews'
import { RichText } from 'prismic-reactjs'

import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import Tags from '../../components/Tags'
import { PageTypes } from '../../enums/enums'
import { Query } from '../../generated/graphql'

import './Search.scss'

type SearchTemplateProps = PageProps<Query> & WithPrismicPreviewProps<Query>

const Search: React.FC<SearchTemplateProps> = ({ data }) => {
  if (!data) return null
  const search = data.prismicSearch.data
  const tags = data.allPrismicTag

  return (
    <Layout page={PageTypes.SEARCH}>
      <Seo title="Search" />
      <div className="wrapper">
        <div className="search">
          <h3>{RichText.asText(search.title.richText)}</h3>
          <p>{RichText.asText(search.description.richText)}</p>
        </div>
        <Tags tags={tags} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query SearchQuery {
    prismicSearch {
      _previewable
      data {
        description {
          richText
        }
        title {
          richText
        }
      }
    }
    allPrismicTag {
      nodes {
        data {
          tag {
            richText
          }
        }
        id
        url
      }
    }
  }
`

export default withPrismicPreview(Search)
