exports.linkResolver = (doc) => {
  switch (doc.type) {
    case 'about': {
      return `/about`
    }
    case 'author': {
      return `/author/${doc.uid}`
    }
    case 'park': {
      return `/park/${doc.uid}`
    }
    case 'post': {
      return `/${doc.uid}`
    }
    case 'search': {
      return `/search`
    }
    case 'tag': {
      return `/tag/${doc.uid}`
    }

    default:
      return '/'
  }
}
