import * as React from 'react'

import { RichText } from 'prismic-reactjs'

import { PrismicHomeDataType } from '../../generated/graphql'
import logo from '../../images/logo.svg'

import './HomeHeader.scss'

interface HomeHeaderProps {
  home: PrismicHomeDataType
}

const HomeHeader: React.FC<HomeHeaderProps> = ({ home }) => {
  if (!home) return null

  return (
    <div className="home upper">
      <img alt="logo" src={logo} />
      <h4>{RichText.asText(home.description.richText)}</h4>
    </div>
  )
}

export default HomeHeader
