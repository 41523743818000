import * as React from 'react'

import classNames from 'classnames'
import { useKeenSlider } from 'keen-slider/react'

import 'keen-slider/keen-slider.min.css'
import './ImageCarousel.scss'

interface ImageCarouselProps {
  images: Array<{
    title: string
    url: string
  }>
}

function Arrow(props: { left?: boolean; onClick: (event: any) => void }) {
  return (
    <svg
      onClick={props.onClick}
      className={classNames('arrow', { left: props.left, right: !props.left })}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      {props.left && <path d="M16.67 0l2.83 2.829-9.339 9.175 9.339 9.167-2.83 2.829-12.17-11.996z" />}
      {!props.left && <path d="M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z" />}
    </svg>
  )
}

const ImageCarousel: React.FC<ImageCarouselProps> = ({ images }) => {
  const [activeSlide, setActiveSlide] = React.useState(0)
  const [loaded, setLoaded] = React.useState<boolean[]>([])
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    animationEnded(slider) {
      setActiveSlide(slider.track.details.rel)
    },
    initial: 0,
    loop: true,
  })

  React.useEffect(() => {
    const newLoaded = [...loaded]
    newLoaded[activeSlide] = true
    setLoaded(newLoaded)
  }, [activeSlide])

  return (
    <div className="image-carousel park-section">
      <div className="image-navigation">
        <div className="keen-slider" ref={sliderRef}>
          {images.map((image, index) => (
            <div className="keen-slider__slide lazy__slide" key={index}>
              <div className="image-wrapper">
                <img alt={image.title} src={loaded[index] ? image.url : ''} />
              </div>
              <p>{image.title}</p>
            </div>
          ))}
        </div>
      </div>
      {instanceRef.current && (
        <div className="dots">
          <Arrow left onClick={(event: any) => event.stopPropagation() || instanceRef.current?.prev()} />
          {[...Array(instanceRef.current.track.details.slides.length).keys()].map((index) => (
            <button
              className={classNames('dot', { active: activeSlide === index })}
              key={index}
              onClick={() => {
                instanceRef.current?.moveToIdx(index)
              }}
            />
          ))}
          <Arrow onClick={(event: any) => event.stopPropagation() || instanceRef.current?.next()} />
        </div>
      )}
    </div>
  )
}

export default ImageCarousel
