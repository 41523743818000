import * as React from 'react'

import classNames from 'classnames'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Hamburger from 'hamburger-react'

import { PageTypes } from '../../enums/enums'
import { PrismicNavigationDataTopNavigation } from '../../generated/graphql'
import camper from '../../images/camper.svg'

import './Header.scss'

interface HeaderProps {
  page: PageTypes
}

const Header: React.FC<HeaderProps> = ({ page }) => {
  const [isOpen, setOpen] = React.useState(false)

  const queryData = useStaticQuery(graphql`
    {
      prismicNavigation {
        data {
          top_navigation {
            link {
              url
              link_type
            }
            link_label {
              text
            }
          }
        }
      }
    }
  `)

  const navigation = queryData.prismicNavigation
  const topNav = navigation.data.top_navigation

  return (
    <nav className="header">
      <ul>
        <li className="edge">
          <Link to="/">
            <img alt="camper" src={camper} />
            <span>Adventure in the Parks</span>
          </Link>
        </li>
        {topNav.map((navItem: PrismicNavigationDataTopNavigation, index: number) => (
          <li className={classNames('secondary', { current: page === navItem.link_label.text })} key={`link-${index}`}>
            <Link to={navItem.link.url}>{navItem.link_label.text}</Link>
          </li>
        ))}
      </ul>
      <div className={classNames('mobile-nav', { open: isOpen })}>
        <div className="hamburger">
          <Hamburger size={16} toggled={isOpen} toggle={setOpen} />
        </div>
        <div className="drawer-wrapper">
          <div className="drawer">
            <ul>
              {topNav.map((navItem: PrismicNavigationDataTopNavigation, index: number) => (
                <li className={classNames({ current: page === navItem.link_label.text })} key={`link-${index}`}>
                  <Link to={navItem.link.url}>{navItem.link_label.text}</Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  )
}

export default Header
