import * as React from 'react'

import { graphql } from 'gatsby'

import MapViewer from '../../components/MapViewer'
import { PrismicPostDataBodyMap } from '../../generated/graphql'
import { builMapDataFromSlice } from '../../utils/mapHelper'

import './SliceMap.scss'

interface SliceMapProp {
  slice: PrismicPostDataBodyMap
}

const SliceMap: React.FC<SliceMapProp> = ({ slice }) => {
  const mapData = builMapDataFromSlice(slice)

  return (
    <div className="slice-map">
      <MapViewer mapData={mapData} />
    </div>
  )
}

export const query = graphql`
  fragment PostDataBodyMap on PrismicPostDataBodyMap {
    items {
      location_icon
      location_kml {
        url
      }
      location_point {
        longitude
        latitude
      }
      location_text {
        html
      }
      location_title
      location_type
    }
    primary {
      map_center {
        latitude
        longitude
      }
      map_title
      map_zoom
    }
  }
`

export default SliceMap
