import * as React from 'react'

import { PrismicPostDataBodySlicesType } from '../../generated/graphql'
import { SliceCarousel, SliceImage, SliceMap, SliceText, SliceQuote, SliceVimeo } from '../../slices'

import './SliceZone.scss'

interface SliceZoneProps {
  slices: PrismicPostDataBodySlicesType[]
}

const SliceZone: React.FC<SliceZoneProps> = ({ slices }) => {
  const sliceComponents = {
    carousel: SliceCarousel,
    image: SliceImage,
    map: SliceMap,
    text: SliceText,
    quote: SliceQuote,
    vimeo: SliceVimeo,
  }

  const sliceArray = slices.map((slice: PrismicPostDataBodySlicesType) => {
    const SliceComponent = sliceComponents[slice.slice_type]

    if (SliceComponent) {
      return (
        <div className="slice" key={slice.id}>
          <SliceComponent slice={slice} />
        </div>
      )
    }

    return null
  })

  return <>{sliceArray}</>
}

export default SliceZone
