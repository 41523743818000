import * as React from 'react'

import { PageProps, graphql } from 'gatsby'
import { withPrismicPreview, WithPrismicPreviewProps } from 'gatsby-plugin-prismic-previews'

import HomeHeader from '../../components/HomeHeader'
import Layout from '../../components/Layout'
import Posts from '../../components/Posts'
import Seo from '../../components/Seo'
import { PageTypes } from '../../enums/enums'
import { Query } from '../../generated/graphql'

import './Home.scss'

type HomeTemplateProps = PageProps<Query> & WithPrismicPreviewProps<Query>

const Home: React.FC<HomeTemplateProps> = ({ data, path }) => {
  if (!data) return null
  const home = data.prismicHome.data
  const posts = data.allPrismicPost

  const page = path === '/' ? PageTypes.HOME : PageTypes.NONE

  return (
    <Layout page={page}>
      <Seo title="Home" />
      <div className="wrapper">
        <HomeHeader home={home} />
        <Posts allowPrimary posts={posts} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query HomeQuery($limit: Int!, $skip: Int!) {
    prismicHome {
      _previewable
      data {
        description {
          richText
        }
        title {
          richText
        }
      }
    }
    allPrismicPost(
      filter: { data: { visible_on_list: { eq: true } } }
      limit: $limit
      skip: $skip
      sort: { fields: data___date, order: DESC }
    ) {
      nodes {
        ...PrismicPostNodes
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`

export default withPrismicPreview(Home)
