import { toDate } from 'date-fns-tz'

export function dateToString(value: string): string {
  const date: Date = toDate(value)
  return date
    ? new Intl.DateTimeFormat('en-US', {
        month: 'short',
        day: '2-digit',
        year: 'numeric',
      }).format(date)
    : ''
}
