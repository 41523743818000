import * as React from 'react'

import classNames from 'classnames'
import { graphql } from 'gatsby'
import { Elements, RichText } from 'prismic-reactjs'

import { PrismicPostDataBodyText } from '../../generated/graphql'

import './SliceText.scss'

interface SliceTextProp {
  slice: PrismicPostDataBodyText
}

function htmlSerializer(type: Elements, element: any) {
  switch (type) {
    case Elements.image:
      return React.createElement('img', { alt: element.alt || '', src: element.url })

    default:
      return null
  }
}

const SliceText: React.FC<SliceTextProp> = ({ slice }) => (
  <div className={classNames('slice-text', slice.slice_label)}>
    <RichText htmlSerializer={htmlSerializer} render={slice.primary.text.richText || []} />
  </div>
)

export const query = graphql`
  fragment PostDataBodyText on PrismicPostDataBodyText {
    primary {
      text {
        richText
      }
    }
    slice_label
  }
`

export default SliceText
