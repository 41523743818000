import { asText } from '@prismicio/helpers'

import { PrismicPost, PrismicPostDataBodyText } from '../generated/graphql'

export function truncate(text: string, maxLength: number): string {
  if (text.length <= maxLength) {
    return text
  }
  const truncatedText = text.substring(0, maxLength)
  return `${truncatedText.substring(0, truncatedText.lastIndexOf(' '))}...`
}

export function firstParagraph(post: PrismicPost): string {
  const firstTextSlice: PrismicPostDataBodyText = post.data.body.find((slice) => slice.slice_type === 'text')
  if (firstTextSlice == null) {
    return ''
  }

  return truncate(asText(firstTextSlice.primary.text.richText), 500)
}
