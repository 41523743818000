import * as React from 'react'

import { graphql } from 'gatsby'

import { PrismicPostDataBodyImage } from '../../generated/graphql'

import './SliceImage.scss'

interface SliceImageProp {
  slice: PrismicPostDataBodyImage
}

const SliceImage: React.FC<SliceImageProp> = ({ slice }) => (
  <div className="slice-image">
    <img alt={slice.primary.image.alt} src={slice.primary.image.thumbnails.large.url} />
  </div>
)

export const query = graphql`
  fragment PostDataBodyImage on PrismicPostDataBodyImage {
    primary {
      image {
        alt
        thumbnails {
          large {
            url
          }
          medium {
            url
          }
          small {
            url
          }
        }
        url
      }
    }
  }
`

export default SliceImage
