import * as React from 'react'

import { graphql, PageProps } from 'gatsby'
import { withPrismicPreview, WithPrismicPreviewProps } from 'gatsby-plugin-prismic-previews'
import { RichText } from 'prismic-reactjs'

import Layout from '../../components/Layout'
import Posts from '../../components/Posts'
import Seo from '../../components/Seo'
import { Query } from '../../generated/graphql'

import './Author.scss'

type AuthorProps = PageProps<Query> & WithPrismicPreviewProps<Query>

const Author: React.FC<AuthorProps> = ({ data }) => {
  if (!data) return null
  const author = data.prismicAuthor.data
  const posts = data.allPrismicPost

  return (
    <Layout>
      <Seo title={RichText.asText(author.author.richText)} />
      <div className="wrapper">
        <div className="upper">
          <h1>Author: {RichText.asText(author.author.richText)}</h1>
        </div>
        <Posts posts={posts} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AutherQuery($prismicId: ID, $limit: Int!, $skip: Int!) {
    prismicAuthor(prismicId: { eq: $prismicId }) {
      _previewable
      data {
        author {
          richText
        }
      }
      prismicId
      url
      uid
    }
    allPrismicPost(
      filter: {
        data: { authors: { elemMatch: { author: { id: { eq: $prismicId } } } }, visible_on_list: { eq: true } }
      }
      limit: $limit
      skip: $skip
      sort: { fields: data___date, order: DESC }
    ) {
      nodes {
        ...PrismicPostNodes
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`
export default withPrismicPreview(Author)
