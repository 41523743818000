import * as React from 'react'

import { Wrapper, Status } from '@googlemaps/react-wrapper'
import { isLatLngLiteral } from '@googlemaps/typescript-guards'
import { createCustomEqual } from 'fast-equals'

import { MapDataType } from '../../utils/mapHelper'

import './MapViewer.scss'

interface InfoWindowProps extends google.maps.InfoWindowOpenOptions {
  infoWindow: google.maps.InfoWindow | undefined
  setInfoWindow: React.Dispatch<React.SetStateAction<google.maps.InfoWindow | undefined>>
}

interface KmlLayerProps extends google.maps.KmlLayerOptions {
  kmlData: any
}

interface MapProps extends google.maps.MapOptions {
  children?: React.ReactNode
  map: google.maps.Map | undefined
  setMap?: React.Dispatch<React.SetStateAction<google.maps.Map | undefined>>
  style: { [key: string]: string }
}

interface MarkerProps extends google.maps.MarkerOptions {
  markerData: any
}

interface MapViewerProps {
  mapData: MapDataType
}

const deepCompareEqualsForMaps = createCustomEqual((deepEqual) => (a: any, b: any) => {
  if (isLatLngLiteral(a) || a instanceof google.maps.LatLng || isLatLngLiteral(b) || b instanceof google.maps.LatLng) {
    return new google.maps.LatLng(a).equals(new google.maps.LatLng(b))
  }
  return deepEqual(a, b)
})

function useDeepCompareMemoize(value: any) {
  const ref = React.useRef()

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

function useDeepCompareEffectForMaps(callback: React.EffectCallback, dependencies: any[]) {
  React.useEffect(callback, dependencies.map(useDeepCompareMemoize))
}

const InfoWindow: React.FC<InfoWindowProps> = ({ infoWindow, setInfoWindow }) => {
  React.useEffect(() => {
    if (!infoWindow) {
      setInfoWindow(new google.maps.InfoWindow())
    }
  }, [infoWindow])

  return <></>
}

const Map: React.FC<MapProps> = ({ map, setMap, style, children, ...options }) => {
  const ref = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (ref.current && !map) {
      setMap(new window.google.maps.Map(ref.current, {}))
    }
  }, [ref, map])

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions(options)
    }
  }, [map, options])

  React.useEffect(() => {
    if (map) {
      ;['click', 'idle'].forEach((eventName) => google.maps.event.clearListeners(map, eventName))
    }
  }, [map])

  return (
    <>
      <div ref={ref} style={style} />
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, { map })
        }
        return null
      })}
    </>
  )
}

const KmlLayer: React.FC<KmlLayerProps> = ({ kmlData, ...options }) => {
  const { mapObject, setMapObject } = kmlData
  const kmlOptions = {
    ...options,
    preserveViewport: true,
    suppressInfoWindows: true,
  }

  React.useEffect(() => {
    if (!mapObject) {
      setMapObject(new google.maps.KmlLayer())
    }

    return () => {
      if (mapObject) {
        mapObject.setMap(null)
      }
    }
  }, [mapObject])

  if (!kmlData.isShowing) {
    kmlOptions.map = null
  }

  React.useEffect(() => {
    if (mapObject) {
      mapObject.setOptions(kmlOptions)
      mapObject.addListener('click', (e: google.maps.MapMouseEvent) => {
        kmlData.onClick(kmlData, e.latLng)
      })
    }
  }, [mapObject, kmlOptions])

  return null
}

const Marker: React.FC<MarkerProps> = ({ markerData, ...options }) => {
  const { mapObject, setMapObject } = markerData
  const markerOptions = {
    ...options,
    icon: {
      anchor: new google.maps.Point(15, 15),
      scaledSize: new google.maps.Size(32, 32),
      url: markerData.icon,
    },
  }

  React.useEffect(() => {
    if (!mapObject) {
      setMapObject(new google.maps.Marker())
    }

    return () => {
      if (mapObject) {
        mapObject.setMap(null)
      }
    }
  }, [mapObject])

  if (!markerData.isShowing) {
    markerOptions.map = null
  }

  React.useEffect(() => {
    if (mapObject) {
      mapObject.setOptions(markerOptions)
      mapObject.addListener('click', (e: google.maps.MapMouseEvent) => {
        markerData.onClick(markerData, e.latLng)
      })
    }
  }, [mapObject, markerOptions])

  return null
}

const render = (status: Status): React.ReactElement => {
  if (status === Status.LOADING) return <h3>{status} ..</h3>
  if (status === Status.FAILURE) return <h3>{status} ...</h3>
  return null
}

const MapViewer: React.FC<MapViewerProps> = ({ mapData }) => {
  const { map, setMap, infoWindow, setInfoWindow } = mapData

  return (
    <Wrapper apiKey={process.env.GATSBY_GOOGLE_MAPS_API_KEY} render={render}>
      <Map
        center={mapData.center}
        map={map}
        mapTypeId="terrain"
        setMap={setMap}
        style={{ flexGrow: '1', height: '100%' }}
        zoom={mapData.zoom}
      >
        <InfoWindow infoWindow={infoWindow} setInfoWindow={setInfoWindow} />
        {mapData.kmlFiles.map((kmlData, index) => (
          <KmlLayer key={index} kmlData={kmlData} url={kmlData.url} />
        ))}
        {mapData.markers.map((markerData, index) => (
          <Marker
            icon={markerData.icon}
            key={index}
            markerData={markerData}
            position={markerData.point}
            title={markerData.title}
          />
        ))}
      </Map>
    </Wrapper>
  )
}

export default MapViewer
