import * as React from 'react'

import { PrismicPostConnection } from '../../generated/graphql'
import Pagination from '../Pagination'
import PostPreview from '../PostPreview'

import './Posts.scss'

interface PostsProps {
  allowPrimary?: boolean
  posts: PrismicPostConnection
}

const Posts: React.FC<PostsProps> = ({ allowPrimary, posts }) => {
  if (!posts) return null
  const postNodes = posts.nodes

  return (
    <div className="posts">
      <div>
        {postNodes.map((post, index) => {
          const isPrimary = index === 0 && allowPrimary
          return <PostPreview post={post} isPrimary={isPrimary} key={post.id} />
        })}
      </div>
      <Pagination pageInfo={posts.pageInfo} />
    </div>
  )
}

Posts.defaultProps = {
  allowPrimary: false,
}

export default Posts
