import * as React from 'react'

import classNames from 'classnames'

import { PrismicPost } from '../../generated/graphql'
import PostDetails from '../PostDetails'

import './PostPreview.scss'

interface PostPreviewProps {
  isPrimary?: boolean
  post: PrismicPost
}

const PostPreview: React.FC<PostPreviewProps> = ({ isPrimary, post }) => (
  <div className={classNames('post-preview', { primary: isPrimary })} key={post.id}>
    <div className={classNames({ container: isPrimary, 'grid-x': !isPrimary })}>
      <div className="post-preview-image">
        <img alt={post.data.primary_photo.alt} src={post.data.primary_photo.thumbnails.large.url} />
      </div>
      <div className="post-preview-details">
        <PostDetails post={post} />
      </div>
    </div>
  </div>
)

PostPreview.defaultProps = {
  isPrimary: false,
}

export default PostPreview
