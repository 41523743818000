import * as React from 'react'

import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { PrismicPost } from '../../generated/graphql'
import favicong from '../../images/favicon.png'
import { dateToString } from '../../utils/dates'
import { firstParagraph } from '../../utils/postHelper'

import './PostDetails.scss'

interface PostPreviewProps {
  post: PrismicPost
  index?: number
  isFullPost?: boolean
}

const FirstParagraph: React.FC<PostPreviewProps> = ({ post, isFullPost }) => {
  if (isFullPost === false) {
    const text: string = firstParagraph(post)
    if (text) {
      return <p className="preview-text">{text}</p>
    }
  }
  return null
}

const PostDetails: React.FC<PostPreviewProps> = ({ post, isFullPost }) => {
  let postDate: string
  if (post.data.date) {
    postDate = dateToString(post.data.date)
  }
  const postAuthors = post.data.authors
    .filter((author) => author && author.author.document)
    .map((author) => author.author.document)
  const postParks = post.data.parks.filter((park) => park && park.park.document).map((park) => park.park.document)
  const postTags = post.data.tags.filter((tag) => tag && tag.tag.document).map((tag) => tag.tag.document)

  const TitleWrapper = isFullPost ? 'div' : Link
  const TitleDiv = isFullPost ? 'h1' : 'h3'

  return (
    <div className="post-details-wrapper">
      <div className="post-details">
        <p className="post-meta-tags">
          {postTags.map((tag) => (
            <Link className="post-tag" key={tag.id} to={tag.url}>
              {RichText.asText(tag.data.tag.richText)}
            </Link>
          ))}
        </p>
        <TitleWrapper to={post.url}>
          <TitleDiv>{RichText.asText(post.data.title.richText)}</TitleDiv>
        </TitleWrapper>
        <p className="post-meta">
          {postAuthors.map((author) => (
            <Link className="post-author" key={author.id} to={author.url}>
              {RichText.asText(author.data.author.richText)}
            </Link>
          ))}
          <span>
            <time>{postDate}</time>
          </span>
        </p>
        <p className="post-meta-parks">
          {isFullPost &&
            postParks.map((park) => (
              <Link className="post-park" key={park.id} to={park.url}>
                <span>
                  <img alt="park" src={favicong} />
                </span>
                {RichText.asText(park.data.title.richText)}
              </Link>
            ))}
        </p>
        <FirstParagraph post={post} isFullPost={isFullPost} />
      </div>
    </div>
  )
}

PostDetails.defaultProps = {
  isFullPost: false,
}

export default PostDetails
