import circle0 from '../images/icons/circle-0.svg'
import circle1 from '../images/icons/circle-1.svg'
import circle2 from '../images/icons/circle-2.svg'
import circle3 from '../images/icons/circle-3.svg'
import circle4 from '../images/icons/circle-4.svg'
import circle5 from '../images/icons/circle-5.svg'
import circle6 from '../images/icons/circle-6.svg'
import circle7 from '../images/icons/circle-7.svg'
import circle8 from '../images/icons/circle-8.svg'
import circle9 from '../images/icons/circle-9.svg'
import circleA from '../images/icons/circle-a.svg'
import circleB from '../images/icons/circle-b.svg'
import circleC from '../images/icons/circle-c.svg'
import circleD from '../images/icons/circle-d.svg'
import circleE from '../images/icons/circle-e.svg'
import circleF from '../images/icons/circle-f.svg'
import circleG from '../images/icons/circle-g.svg'
import circleH from '../images/icons/circle-h.svg'
import circleI from '../images/icons/circle-i.svg'
import circleJ from '../images/icons/circle-j.svg'
import circleK from '../images/icons/circle-k.svg'
import circleL from '../images/icons/circle-l.svg'
import circleM from '../images/icons/circle-m.svg'
import circleMinus from '../images/icons/circle-minus.svg'
import circleN from '../images/icons/circle-n.svg'
import circleO from '../images/icons/circle-o.svg'
import circleP from '../images/icons/circle-p.svg'
import circlePause from '../images/icons/circle-pause.svg'
import circlePlay from '../images/icons/circle-play.svg'
import circlePlus from '../images/icons/circle-plus.svg'
import circleQ from '../images/icons/circle-q.svg'
import circleQuestion from '../images/icons/circle-question.svg'
import circleR from '../images/icons/circle-r.svg'
import circleS from '../images/icons/circle-s.svg'
import circleStar from '../images/icons/circle-star.svg'
import circleStop from '../images/icons/circle-stop.svg'
import circleT from '../images/icons/circle-t.svg'
import circleU from '../images/icons/circle-u.svg'
import circleV from '../images/icons/circle-v.svg'
import circleW from '../images/icons/circle-w.svg'
import circleX from '../images/icons/circle-x.svg'
import circleY from '../images/icons/circle-y.svg'
import circleZ from '../images/icons/circle-z.svg'
import houseChimney from '../images/icons/house-chimney.svg'
import houseFlag from '../images/icons/house-flag.svg'
import tree from '../images/icons/tree.svg'

export enum MapObjectSubTypes {
  MARKER_VISITOR_CENTER = 'marker_visitor_center',
  BOUNDARY_NATIONAL_PARK = 'boundary_national_park',
  BOUNDARY_INDIGENOUS_TERRITORY = 'boundary_indigenous_territory',
}

export enum MapPinTypes {
  CIRCLE_0 = circle0,
  CIRCLE_1 = circle1,
  CIRCLE_2 = circle2,
  CIRCLE_3 = circle3,
  CIRCLE_4 = circle4,
  CIRCLE_5 = circle5,
  CIRCLE_6 = circle6,
  CIRCLE_7 = circle7,
  CIRCLE_8 = circle8,
  CIRCLE_9 = circle9,
  CIRCLE_A = circleA,
  CIRCLE_B = circleB,
  CIRCLE_C = circleC,
  CIRCLE_D = circleD,
  CIRCLE_E = circleE,
  CIRCLE_F = circleF,
  CIRCLE_G = circleG,
  CIRCLE_H = circleH,
  CIRCLE_I = circleI,
  CIRCLE_J = circleJ,
  CIRCLE_K = circleK,
  CIRCLE_L = circleL,
  CIRCLE_M = circleM,
  CIRCLE_MINUS = circleMinus,
  CIRCLE_N = circleN,
  CIRCLE_O = circleO,
  CIRCLE_P = circleP,
  CIRCLE_PAUSE = circlePause,
  CIRCLE_PLAY = circlePlay,
  CIRCLE_PLUS = circlePlus,
  CIRCLE_Q = circleQ,
  CIRCLE_QUESTION = circleQuestion,
  CIRCLE_R = circleR,
  CIRCLE_S = circleS,
  CIRCLE_STAR = circleStar,
  CIRCLE_STOP = circleStop,
  CIRCLE_T = circleT,
  CIRCLE_U = circleU,
  CIRCLE_V = circleV,
  CIRCLE_W = circleW,
  CIRCLE_X = circleX,
  CIRCLE_Y = circleY,
  CIRCLE_Z = circleZ,
  TREE = tree,
  HOUSE_FLAG = houseFlag,
  HOUSE_CHIMNEY = houseChimney,
}

export enum MapObjectTypes {
  MARKER = 'Marker',
  KML_LAYER = 'KmlLayer',
}

export enum PageTypes {
  ABOUT = 'About',
  HOME = 'Home',
  NONE = '',
  SEARCH = 'Search',
}
