import * as React from 'react'

import { graphql, PageProps } from 'gatsby'
import { withPrismicPreview, WithPrismicPreviewProps } from 'gatsby-plugin-prismic-previews'
import { RichText } from 'prismic-reactjs'

import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { PageTypes } from '../../enums/enums'
import { Query } from '../../generated/graphql'

import './About.scss'

type AboutProps = PageProps<Query> & WithPrismicPreviewProps<Query>

const About: React.FC<AboutProps> = ({ data }) => {
  if (!data) return null
  const about = data.prismicAbout.data

  return (
    <Layout page={PageTypes.ABOUT}>
      <Seo title="About" />
      <div className="wrapper">
        <div className="about">
          <h3>{RichText.asText(about.title.richText)}</h3>
          <p>{RichText.asText(about.description.richText)}</p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query AboutQuery {
    prismicAbout {
      _previewable
      data {
        description {
          richText
        }
        title {
          richText
        }
      }
    }
  }
`

export default withPrismicPreview(About)
