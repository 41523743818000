import * as React from 'react'

import { graphql, PageProps } from 'gatsby'
import { withPrismicPreview, WithPrismicPreviewProps } from 'gatsby-plugin-prismic-previews'
import { RichText } from 'prismic-reactjs'

import Layout from '../../components/Layout'
import Posts from '../../components/Posts'
import Seo from '../../components/Seo'
import { Query } from '../../generated/graphql'

import './Tag.scss'

type TagProps = PageProps<Query> & WithPrismicPreviewProps<Query>

const Tag: React.FC<TagProps> = ({ data }) => {
  if (!data) return null
  const tag = data.prismicTag.data
  const posts = data.allPrismicPost

  return (
    <Layout>
      <Seo title={RichText.asText(tag.tag.richText)} />
      <div className="wrapper">
        <div className="upper">
          <h1>Tag: {RichText.asText(tag.tag.richText)}</h1>
        </div>
        <Posts posts={posts} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query TagQuery($prismicId: ID, $limit: Int!, $skip: Int!) {
    prismicTag(prismicId: { eq: $prismicId }) {
      _previewable
      data {
        tag {
          richText
        }
      }
      prismicId
      url
      uid
    }
    allPrismicPost(
      filter: { data: { tags: { elemMatch: { tag: { id: { eq: $prismicId } } } }, visible_on_list: { eq: true } } }
      limit: $limit
      skip: $skip
      sort: { fields: data___date, order: DESC }
    ) {
      nodes {
        ...PrismicPostNodes
      }
      pageInfo {
        currentPage
        pageCount
      }
    }
  }
`
export default withPrismicPreview(Tag)
