import * as React from 'react'

import classNames from 'classnames'
import { graphql, PageProps, Link } from 'gatsby'
import { withPrismicPreview, WithPrismicPreviewProps } from 'gatsby-plugin-prismic-previews'
import { RichText } from 'prismic-reactjs'

import Layout from '../../components/Layout'
import PostDetails from '../../components/PostDetails'
import Seo from '../../components/Seo'
import SliceZone from '../../components/SliceZone'
import { Query } from '../../generated/graphql'
import { isNullOrUndefined } from '../../utils/compare'
import { firstParagraph } from '../../utils/postHelper'

import './Post.scss'

type PostTemplateProps = PageProps<Query> & WithPrismicPreviewProps<Query>

const Post: React.FC<PostTemplateProps> = ({ data }) => {
  if (!data) return null
  const post = data.prismicPost.data

  const hasPostPrevious = !isNullOrUndefined(data.prismicPostPrevious)
  const contentPostPrevious = hasPostPrevious ? (
    <Link to={data.prismicPostPrevious.url}>Previous</Link>
  ) : (
    <span>Previous</span>
  )

  const hasPostNext = !isNullOrUndefined(data.prismicPostNext)
  const contentPostNextPost = hasPostNext ? <Link to={data.prismicPostNext.url}>Next</Link> : <span>Next</span>

  return (
    <Layout>
      <Seo title={RichText.asText(post.title.richText)} description={firstParagraph(data.prismicPost)} />
      <div className="wrapper post">
        <img alt={post.primary_photo.alt} src={post.primary_photo.thumbnails.large.url} />
        <div className="post-full">
          <PostDetails post={data.prismicPost} isFullPost />
          <SliceZone slices={post.body} />
          <div>
            <nav>
              <ul className="pagination">
                <li className={classNames('pagination-previous', { disabled: !hasPostPrevious })}>
                  {contentPostPrevious}
                </li>
                <li className={classNames('pagination-next', { disabled: !hasPostNext })}>{contentPostNextPost}</li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const queryPrismicPostNodes = graphql`
  fragment PrismicPostNodes on PrismicPost {
    _previewable
    data {
      date
      title {
        richText
      }
      primary_photo {
        alt
        thumbnails {
          large {
            url
          }
          medium {
            url
          }
          small {
            url
          }
        }
        url
      }
      body {
        ... on PrismicSliceType {
          id
          slice_label
          slice_type
        }
        ...PostDataBodyCarousel
        ...PostDataBodyImage
        ...PostDataBodyMap
        ...PostDataBodyQuote
        ...PostDataBodyText
        ...PostDataBodyVimeo
      }
      authors {
        author {
          document {
            ... on PrismicAuthor {
              id
              data {
                author {
                  richText
                }
              }
              url
            }
          }
        }
      }
      parks {
        park {
          document {
            ... on PrismicPark {
              id
              data {
                title {
                  richText
                }
              }
              url
            }
          }
        }
      }
      tags {
        tag {
          document {
            ... on PrismicTag {
              id
              data {
                tag {
                  richText
                }
              }
              url
            }
          }
        }
      }
    }
    id
    prismicId
    url
    uid
  }
`

export const query = graphql`
  query PostQuery($prismicId: ID, $prismicPreviousId: ID, $prismicNextId: ID) {
    prismicPostPrevious: prismicPost(prismicId: { eq: $prismicPreviousId }) {
      url
    }
    prismicPostNext: prismicPost(prismicId: { eq: $prismicNextId }) {
      url
    }
    prismicPost(prismicId: { eq: $prismicId }) {
      ...PrismicPostNodes
    }
  }
`
export default withPrismicPreview(Post)
