import * as React from 'react'

import { PageTypes } from '../../enums/enums'
import Footer from '../Footer'
import Header from '../Header'

import './Layout.scss'

interface LayoutProps {
  page?: PageTypes
  children?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ page, children }) => (
  <div className="page-wrapper">
    <Header page={page} />
    <main>{children}</main>
    <Footer />
  </div>
)

Layout.defaultProps = {
  page: PageTypes.NONE,
}

export default Layout
