import * as React from 'react'

import { Link } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { PrismicTagConnection } from '../../generated/graphql'

import './Tags.scss'

interface TagsProps {
  tags: PrismicTagConnection
}

const Tags: React.FC<TagsProps> = ({ tags }) => {
  if (!tags) return null
  const tagNodes = tags.nodes

  return (
    <div className="tags">
      {tagNodes.map((tag) => (
        <Link to={tag.url} key={tag.id}>
          {RichText.asText(tag.data.tag.richText)}
        </Link>
      ))}
    </div>
  )
}

export default Tags
