import * as React from 'react'

import { graphql } from 'gatsby'
import { RichText } from 'prismic-reactjs'

import { PrismicPostDataBodyQuote } from '../../generated/graphql'

import './SliceQuote.scss'

interface SliceQuoteProp {
  slice: PrismicPostDataBodyQuote
}

const SliceQuote: React.FC<SliceQuoteProp> = ({ slice }) => {
  const AuthorWrapper = slice.primary.link?.url ? 'a' : 'p'

  return (
    <blockquote className="slice-quote">
      <div className="quote">
        <RichText render={slice.primary.quote.richText || []} />
      </div>
      <AuthorWrapper className="author" target="_blank" href={slice.primary.link.url}>
        {slice.primary.author}
      </AuthorWrapper>
    </blockquote>
  )
}

export const query = graphql`
  fragment PostDataBodyQuote on PrismicPostDataBodyQuote {
    primary {
      author
      link {
        url
      }
      quote {
        richText
      }
    }
  }
`

export default SliceQuote
