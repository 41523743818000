import { componentResolverFromMap } from 'gatsby-plugin-prismic-previews'

import About from '../templates/About'
import Author from '../templates/Author'
import Home from '../templates/Home'
import Post from '../templates/Post'
import Search from '../templates/Search'
import Tag from '../templates/Tag'
import { linkResolver } from './linkResolver'

export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
    componentResolver: componentResolverFromMap({
      about: About,
      author: Author,
      home: Home,
      post: Post,
      search: Search,
      tag: Tag,
    }),
  },
]
