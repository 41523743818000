import * as React from 'react'

import classNames from 'classnames'
import { Link } from 'gatsby'

import { PageInfo } from '../../generated/graphql'

import './Pagination.scss'

const getPageNumberPath = (currentIndex: number) => `/page/${currentIndex}`

interface PaginationProps {
  pageInfo: PageInfo
}

const Pagination: React.FC<PaginationProps> = ({ pageInfo }) => {
  if (!pageInfo) return null
  const { currentPage, pageCount } = pageInfo

  const hasPreviousPage = currentPage > 1
  const previousPageContent = hasPreviousPage ? (
    <Link to={getPageNumberPath(currentPage - 1)}>Previous</Link>
  ) : (
    <span>Previous</span>
  )

  const hasNextPage = currentPage < pageCount
  const nextPageContent = hasNextPage ? <Link to={getPageNumberPath(currentPage + 1)}>Next</Link> : <span>Next</span>

  return (
    <nav>
      <ul className="pagination">
        <li className={classNames('pagination-previous', { disabled: !hasPreviousPage })}>{previousPageContent}</li>
        {Array.from({ length: pageCount }, (_, i) => {
          const page = i + 1
          const isCurrentPage = page === currentPage
          const pageContent = isCurrentPage ? <>{page}</> : <Link to={getPageNumberPath(page)}>{page}</Link>

          return (
            <li className={classNames({ current: isCurrentPage })} key={page}>
              {pageContent}
            </li>
          )
        })}
        <li className={classNames('pagination-next', { disabled: !hasNextPage })}>{nextPageContent}</li>
      </ul>
    </nav>
  )
}

export default Pagination
