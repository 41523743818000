import * as React from 'react'

import { graphql } from 'gatsby'

import ImageCarousel from '../../components/ImageCarousel'
import { PrismicPostDataBodyCarousel } from '../../generated/graphql'

import './SliceCarousel.scss'

interface SliceCarouselProp {
  slice: PrismicPostDataBodyCarousel
}

const SliceCarousel: React.FC<SliceCarouselProp> = ({ slice }) => {
  const images = slice.items.map((item) => ({
    title: item.carousel_image.alt,
    url: item.carousel_image.thumbnails.large.url,
  }))

  return (
    <div className="slice-carousel">
      <ImageCarousel images={images} />
    </div>
  )
}

export const query = graphql`
  fragment PostDataBodyCarousel on PrismicPostDataBodyCarousel {
    items {
      carousel_image {
        alt
        thumbnails {
          small {
            url
          }
          medium {
            url
          }
          large {
            url
          }
        }
        url
      }
    }
  }
`

export default SliceCarousel
