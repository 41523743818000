import * as React from 'react'

import { graphql } from 'gatsby'

import { PrismicPostDataBodyVimeo } from '../../generated/graphql'

import './SliceVimeo.scss'

interface SliceQuoteProp {
  slice: PrismicPostDataBodyVimeo
}

const SliceQuote: React.FC<SliceQuoteProp> = ({ slice }) => {
  const link = `https://player.vimeo.com/video/${slice.primary.vimeo_id}`
  const src = `${link}?h=${slice.primary.vimeo_embed_code}&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`

  return (
    <>
      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <iframe
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          frameBorder="0"
          referrerPolicy="strict-origin-when-cross-origin"
          src={src}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          title={slice.primary.vimeo_title}
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js" />
    </>
  )
}

export const query = graphql`
  fragment PostDataBodyVimeo on PrismicPostDataBodyVimeo {
    primary {
      vimeo_embed_code
      vimeo_id
      vimeo_title
    }
  }
`

export default SliceQuote
