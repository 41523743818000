import * as React from 'react'

import { MapObjectSubTypes, MapObjectTypes, MapPinTypes } from '../enums/enums'
import {
  Maybe,
  PrismicPark,
  PrismicParkDataParkDataIntegrationTypeVisitor_Centers,
  PrismicPostDataBodyMap,
  PrismicPostDataBodyMapItem,
} from '../generated/graphql'

export type PointNumberType = {
  lat: number
  lng: number
}

export type PointStringType = {
  lat: string
  lng: string
}

export type MapObjectType = {
  icon: MapPinTypes
  index: number | string
  infoWindow: google.maps.InfoWindow | undefined
  isShowing: boolean
  mapObject: google.maps.KmlLayer | google.maps.Marker | undefined
  mapObjectSubType: string
  mapObjectType: string
  onClick: (data: MapObjectType, location: google.maps.LatLng | null) => void
  onToggle?: (data: MapObjectType, checked: boolean) => void
  setMapObject: React.Dispatch<React.SetStateAction<google.maps.KmlLayer | google.maps.Marker | undefined>>
  point?: PointNumberType
  text: string
  title: string
  url: string
}

export type MapDataType = {
  center: PointNumberType
  infoWindow: google.maps.InfoWindow | undefined
  kmlFiles: Array<MapObjectType>
  map: google.maps.Map | undefined
  markers: Array<MapObjectType>
  setInfoWindow: React.Dispatch<React.SetStateAction<google.maps.InfoWindow | undefined>>
  setMap: React.Dispatch<React.SetStateAction<google.maps.Map | undefined>>
  zoom: Maybe<number> | undefined
}

export function buildMapDataFromPrismicPark(park: PrismicPark): MapDataType {
  const [map, setMap] = React.useState<google.maps.Map>()
  const [infoWindow, setInfoWindow] = React.useState<google.maps.InfoWindow>()

  const { kml_files, location, visitor_centers } = park.data.park_data

  const mapObjectClick = (data: MapObjectType, clickLocation: google.maps.LatLng | null) => {
    if (infoWindow) {
      let position = clickLocation
      if (position === null && data.point) {
        position = new google.maps.LatLng(data.point.lat, data.point.lng)
      }
      infoWindow.setPosition(position)
      infoWindow.setContent(`<p><b>${data.title}</b></p><p>${data.text}</p>`)
      infoWindow.open(map, data.mapObject)
    }
  }

  const mapObjectToggle = (data: MapObjectType) => {
    data.mapObject?.setMap(data.isShowing ? null : map)
  }

  const createKmlLayer = (
    index: number | string,
    kmlLayerTitle: string,
    kmlLayerText: string,
    mapObjectSubType: string,
    kmlLayerUrl: string,
    icon: MapPinTypes,
  ): MapObjectType => {
    const [mapObject, setMapObject] = React.useState<google.maps.KmlLayer>()

    let isShowing = true
    if (mapObjectSubType === MapObjectSubTypes.BOUNDARY_INDIGENOUS_TERRITORY) {
      isShowing = false
    }

    return {
      icon,
      index,
      infoWindow,
      isShowing,
      mapObject,
      mapObjectSubType,
      mapObjectType: MapObjectTypes.KML_LAYER,
      onClick: mapObjectClick,
      onToggle: mapObjectToggle,
      setMapObject,
      text: kmlLayerText,
      title: kmlLayerTitle,
      url: kmlLayerUrl,
    }
  }

  const parseFloatLocation = (markerLocation: PointStringType): PointNumberType => ({
    lat: parseFloat(markerLocation.lat),
    lng: parseFloat(markerLocation.lng),
  })

  const createMarker = (
    index: number | string,
    markerTitle: string,
    markerText: string,
    markerLocation: PointStringType,
    icon: MapPinTypes,
  ): MapObjectType => {
    const [mapObject, setMapObject] = React.useState<google.maps.Marker>()
    return {
      icon,
      index,
      infoWindow,
      isShowing: true,
      mapObject,
      mapObjectSubType: MapObjectSubTypes.MARKER_VISITOR_CENTER,
      mapObjectType: MapObjectTypes.MARKER,
      onClick: mapObjectClick,
      onToggle: mapObjectToggle,
      point: parseFloatLocation(markerLocation),
      setMapObject,
      text: markerText,
      title: markerTitle,
    }
  }

  const kmlFiles = kml_files.map((kmlFile, index) =>
    createKmlLayer(index, kmlFile.name, '', kmlFile.type, kmlFile.url, MapPinTypes.TREE),
  )

  const visitorCenters = visitor_centers.map(
    (visitorCenter: PrismicParkDataParkDataIntegrationTypeVisitor_Centers, index: number) => {
      let text = ''
      if (visitorCenter.has_passport_stamp) {
        text += 'Has passport stamp<br>'
      }
      text += `${visitorCenter.location.lat}, ${visitorCenter.location.lng}<br>`

      const icon: string = visitorCenter.has_passport_stamp ? MapPinTypes.HOUSE_FLAG : MapPinTypes.HOUSE_CHIMNEY
      return createMarker(index, visitorCenter.name, text, visitorCenter.location, icon)
    },
  )

  return {
    center: parseFloatLocation(location),
    infoWindow,
    kmlFiles,
    map,
    markers: visitorCenters,
    zoom: 8,
    setInfoWindow,
    setMap,
  }
}

export function builMapDataFromSlice(slice: PrismicPostDataBodyMap): MapDataType {
  const [map, setMap] = React.useState<google.maps.Map>()
  const [infoWindow, setInfoWindow] = React.useState<google.maps.InfoWindow>()

  const mapObjectClick = (data: MapObjectType, clickLocation: google.maps.LatLng | null) => {
    if (infoWindow) {
      infoWindow.setPosition(clickLocation)
      infoWindow.setContent(`<p><b>${data.title}</b></p><p>${data.text}</p>`)
      infoWindow.open(map, data.mapObject)
    }
  }

  const createKmlLayer = (index: number, mapItem: PrismicPostDataBodyMapItem): MapObjectType => {
    const [mapObject, setMapObject] = React.useState<google.maps.KmlLayer>()
    return {
      icon: MapPinTypes.CIRCLE_QUESTION,
      index,
      infoWindow,
      isShowing: true,
      mapObject,
      mapObjectSubType: '',
      mapObjectType: MapObjectTypes.KML_LAYER,
      onClick: mapObjectClick,
      setMapObject,
      text: mapItem.location_text?.html,
      title: mapItem.location_title,
      url: mapItem.location_kml.url,
    }
  }

  const createMarker = (index: number, mapItem: PrismicPostDataBodyMapItem): MapObjectType => {
    let icon: MapPinTypes
    switch (mapItem.location_icon) {
      case 'Circle0':
        icon = MapPinTypes.CIRCLE_0
        break
      case 'Circle1':
        icon = MapPinTypes.CIRCLE_1
        break
      case 'Circle2':
        icon = MapPinTypes.CIRCLE_2
        break
      case 'Circle3':
        icon = MapPinTypes.CIRCLE_3
        break
      case 'Circle4':
        icon = MapPinTypes.CIRCLE_4
        break
      case 'Circle5':
        icon = MapPinTypes.CIRCLE_5
        break
      case 'Circle6':
        icon = MapPinTypes.CIRCLE_6
        break
      case 'Circle7':
        icon = MapPinTypes.CIRCLE_7
        break
      case 'Circle8':
        icon = MapPinTypes.CIRCLE_8
        break
      case 'Circle9':
        icon = MapPinTypes.CIRCLE_9
        break
      case 'CircleA':
        icon = MapPinTypes.CIRCLE_A
        break
      case 'CircleB':
        icon = MapPinTypes.CIRCLE_B
        break
      case 'CircleC':
        icon = MapPinTypes.CIRCLE_C
        break
      case 'CircleD':
        icon = MapPinTypes.CIRCLE_D
        break
      case 'CircleE':
        icon = MapPinTypes.CIRCLE_E
        break
      case 'CircleF':
        icon = MapPinTypes.CIRCLE_F
        break
      case 'CircleG':
        icon = MapPinTypes.CIRCLE_G
        break
      case 'CircleH':
        icon = MapPinTypes.CIRCLE_H
        break
      case 'CircleI':
        icon = MapPinTypes.CIRCLE_I
        break
      case 'CircleJ':
        icon = MapPinTypes.CIRCLE_J
        break
      case 'CircleK':
        icon = MapPinTypes.CIRCLE_K
        break
      case 'CircleL':
        icon = MapPinTypes.CIRCLE_L
        break
      case 'CircleM':
        icon = MapPinTypes.CIRCLE_M
        break
      case 'CircleMinus':
        icon = MapPinTypes.CIRCLE_MINUS
        break
      case 'CircleN':
        icon = MapPinTypes.CIRCLE_N
        break
      case 'CircleO':
        icon = MapPinTypes.CIRCLE_O
        break
      case 'CircleP':
        icon = MapPinTypes.CIRCLE_P
        break
      case 'CirclePause':
        icon = MapPinTypes.CIRCLE_PAUSE
        break
      case 'CirclePlay':
        icon = MapPinTypes.CIRCLE_PLAY
        break
      case 'CirclePlus':
        icon = MapPinTypes.CIRCLE_PLUS
        break
      case 'CircleQ':
        icon = MapPinTypes.CIRCLE_Q
        break
      case 'CircleQuestion':
        icon = MapPinTypes.CIRCLE_QUESTION
        break
      case 'CircleR':
        icon = MapPinTypes.CIRCLE_R
        break
      case 'CircleS':
        icon = MapPinTypes.CIRCLE_S
        break
      case 'CircleStar':
        icon = MapPinTypes.CIRCLE_STAR
        break
      case 'CircleStop':
        icon = MapPinTypes.CIRCLE_STOP
        break
      case 'CircleT':
        icon = MapPinTypes.CIRCLE_T
        break
      case 'CircleU':
        icon = MapPinTypes.CIRCLE_U
        break
      case 'CircleV':
        icon = MapPinTypes.CIRCLE_V
        break
      case 'CircleW':
        icon = MapPinTypes.CIRCLE_W
        break
      case 'CircleX':
        icon = MapPinTypes.CIRCLE_X
        break
      case 'CircleY':
        icon = MapPinTypes.CIRCLE_Y
        break
      case 'CircleZ':
        icon = MapPinTypes.CIRCLE_Z
        break
      case 'HouseChimney':
        icon = MapPinTypes.HOUSE_CHIMNEY
        break
      case 'HouseFlag':
        icon = MapPinTypes.HOUSE_FLAG
        break
      case 'Tree':
        icon = MapPinTypes.TREE
        break
      default:
        icon = MapPinTypes.CIRCLE_QUESTION
    }

    const [mapObject, setMapObject] = React.useState<google.maps.Marker>()
    return {
      icon,
      index,
      infoWindow,
      isShowing: true,
      mapObject,
      mapObjectSubType: '',
      mapObjectType: MapObjectTypes.MARKER,
      onClick: mapObjectClick,
      point: {
        lat: mapItem.location_point?.latitude,
        lng: mapItem.location_point?.longitude,
      },
      setMapObject,

      text: mapItem.location_text?.html,
      title: mapItem.location_title,
    }
  }

  const markers = slice.items
    .filter((item) => item.location_type === MapObjectTypes.MARKER)
    .map((item, index) => createMarker(index, item, true))
  const kmlFiles = slice.items
    .filter((item) => item.location_type === MapObjectTypes.KML_LAYER)
    .map((item, index) => createKmlLayer(index, item))

  return {
    center: {
      lat: slice.primary.map_center?.latitude,
      lng: slice.primary.map_center?.longitude,
    },
    infoWindow,
    kmlFiles,
    map,
    markers,
    zoom: slice.primary.map_zoom,
    setInfoWindow,
    setMap,
  }
}
